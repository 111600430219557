<template>
  <div class="hero">
    <h1 class="h2 t-1">Combining public and private initiative to up the quality of traffic systems</h1>
    <div class="hero-bg"></div>
  </div>
</template>

<script>
export default {
  name: 'BusinessHero'
}
</script>

<style lang="scss" scoped>
.hero {
  @include grid;
  .t-1 {
    grid-column: 2 / 8;
    grid-row: 2 / 3;
    margin-bottom: 48px;
    z-index: 2;
    @include laptop {
      margin-bottom: 36px;
    }
    @include tablet {
      margin-bottom: 32px;
    }
    @include phone {
      grid-column: 2 / 6;
      padding-top: 132px;
      margin-bottom: 36px;
    }
  }
  .hero-bg {
    grid-column: 1 / 15;
    grid-row: 1 / 3;
    background-color: $c-h;
    height: 542px;
    z-index: -2;
    @include laptop {
      height: 398px;
    }
    @include tablet {
      grid-column: 1 / 11;
      height: 340px;
    }
    @include phone {
      grid-column: 1 / 7;
      height: 100%;
    }
  }
}

</style>
