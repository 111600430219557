<template>
  <div class="business page">
    <BusinessHero/>
    <BusinessContent1/>
    <BusinessContent2/>
    <BusinessCallToAction/>
    <NavFooter/>
  </div>
</template>

<script>
import BusinessHero from '../components/BusinessHero'
import BusinessContent1 from '../components/BusinessContent1'
import BusinessContent2 from '../components/BusinessContent2'
import BusinessCallToAction from '../components/BusinessCallToAction'
import NavFooter from '../components/NavFooter'

export default {
  name: 'Business',
  components: {
    BusinessHero,
    BusinessContent1,
    BusinessContent2,
    BusinessCallToAction,
    NavFooter
  }
}
</script>

<style lang="scss" scoped>

</style>
