<template>
  <div class="content-1 up-1" v-scrollanimate>
    <h2 class="h4 t-1">A win for everyone</h2>
    <div class="p-1">Our innovations enable collaboration between local authorities, planners, and developers to build urban systems that increase sustainability and quality of life, while providing attractive business opportunities for all.</div>
  </div>
</template>

<script>
export default {
  name: 'BusinessContent1'
}
</script>

<style lang="scss" scoped>
.content-1 {
  @include grid;
  margin-top: 128px;
  @include laptop {
    margin-top: 96px;
  }
  @include tablet {
    margin-top: 64px;
  }
  .t-1 {
    grid-column: 8 / 13;
    grid-row: 1 / 2;
    @include tablet {
      grid-column: 2 / 8;
    }
    @include phone {
      grid-column: 2 / 6;
    }
  }
  .p-1 {
    grid-column: 8 / 13;
    grid-row: 2 / 3;
    margin-top: 54px;
    margin-bottom: 128px;
    @include laptop {
      margin-top: 32px;
      margin-bottom: 96px;
    }
    @include tablet {
      grid-column: 2 / 8;
    }
    @include phone {
      margin-top: 26px;
      margin-bottom: 64px;
      grid-column: 2 / 6;
    }
  }
}

</style>
