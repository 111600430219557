<template>
  <div class="content-2 up-1" v-scrollanimate>
    <hr class="line">
    <h4 class="h3 t-0">How you benefit</h4>
    <picture class="b-image-1">
        <source srcset="~@/assets/b_1.webp" type="image/webp">
        <source srcset="~@/assets/b_1.jpg" type="image/jpeg">
        <img src="~@/assets/b_1.jpg" alt="Municipality" />
    </picture>
    <h5 class="h4 t-1">Saving land</h5>
    <div class="p-1">SRI and CPRS provide needed urban infrastructure while using much less land than existing solutions. SRI can free at least 30% of the land that would have been taken up by any other type of complete system highway interchange, while maintaining the same level of vehicle flow.</div>
    <picture class="b-image-2">
        <source srcset="~@/assets/b_2.webp" type="image/webp">
        <source srcset="~@/assets/b_2.jpg" type="image/jpeg">
        <img src="~@/assets/b_2.jpg" alt="Planners" />
    </picture>
    <h5 class="h4 t-2">New markets</h5>
    <div class="p-2">The small footprint of SRI opens an entirely new market for building complete system highway interchanges in tight locations where such solutions were previously thought of as impossible.</div>
    <picture class="b-image-3">
        <source srcset="~@/assets/b_3.webp" type="image/webp">
        <source srcset="~@/assets/b_3.jpg" type="image/jpeg">
        <img src="~@/assets/b_3.jpg" alt="Parking concession businesses" />
    </picture>
    <h5 class="h4 t-3">Transportation efficiency</h5>
    <div class="p-3">Our combined complex allows commuters to leave their vehicles at the interchange and easily continue their journey to the heart of the city by public transportation. This means optimal access to several means of transportation without getting stuck in traffic. The city benefits from a reduction in traffic jams and delays caused by parking searches, making it more competitive as a high-quality place to live and work in.</div>
    <picture class="b-image-4">
        <source srcset="~@/assets/b_4.webp" type="image/webp">
        <source srcset="~@/assets/b_4.jpg" type="image/jpeg">
        <img src="~@/assets/b_4.jpg" alt="Parking concession businesses" />
    </picture>
    <h5 class="h4 t-4">Economic benefit</h5>
    <div class="p-4">Our solutions encourage modern financing methods. The city offers a tender for constructing the infrastructure, then pays for it by providing firms the right to build and rent out commercial real-estate within the interchange. The city profits twice: from the relocation of land-wasting operations to the salvaged land inside the interchange, and by providing vital infrastructure without having to spend huge public budgets on it.</div>
  </div>
</template>

<script>
export default {
  name: 'BusinessContent1'
}
</script>

<style lang="scss" scoped>
.content-2 {
  @include grid;
  margin-bottom: 128px;
  @include laptop {
    margin-bottom: 96px;
  }
  @include tablet {
    margin-bottom: 64px;
  }
  .line {
    grid-column: 2 / 14;
    grid-row: 1 / 2;
    margin-bottom: 48px;
    @include tablet {
      grid-column: 2 / 10;
    }
    @include phone {
      grid-column: 2 / 6;
      margin-bottom: 26px;
    }
  }
  .t-0 {
    grid-column: 2 / 6;
    grid-row: 2 / 3;
    margin-bottom: 64px;
  }
  .b-image-1 {
    grid-column: 2 / 7;
    grid-row: 3 / 4;
    @include tablet {
      grid-column: 2 / 6;
    }
    @include phone {
      grid-column: 2 / 6;
    }
    img {
      width: 100%;
      vertical-align: bottom;
      @include phone {
        height: 66.7vw;
        object-fit: cover;
      }
    }
  }
  .t-1 {
    grid-column: 2 / 7;
    grid-row: 4 / 5;
    margin-top: 40px;
    @include tablet {
      grid-column: 2 / 6;
    }
    @include phone {
      grid-column: 2 / 6;
    }
  }
  .p-1 {
    grid-column: 2 / 7;
    grid-row: 5 / 6;
    margin-top: 8px;
    margin-bottom: 96px;
    @include laptop {
      margin-bottom: 64px;
    }
    @include tablet {
      grid-column: 2 / 6;
    }
    @include phone {
      grid-column: 2 / 6;
    }
  }
  .b-image-2 {
    grid-column: 8 / 13;
    grid-row: 3 / 4;
    @include tablet {
      grid-column: 6 / 10;
    }
    @include phone {
      grid-column: 2 / 6;
      grid-row: 6 / 7;
    }
    img {
      width: 100%;
      vertical-align: bottom;
      @include phone {
        height: 66.7vw;
        object-fit: cover;
      }
    }
  }
  .t-2 {
    grid-column: 8 / 13;
    grid-row: 4 / 5;
    margin-top: 40px;
    @include tablet {
      grid-column: 6 / 10;
    }
    @include phone {
      grid-column: 2 / 6;
      grid-row: 7 / 8;
    }
  }
  .p-2 {
    grid-column: 8 / 13;
    grid-row: 5 / 6;
    margin-top: 12px;
    margin-bottom: 96px;
    @include laptop {
      margin-bottom: 64px;
    }
    @include tablet {
      grid-column: 6 / 10;
    }
    @include phone {
      grid-column: 2 / 6;
      grid-row: 8 / 9;
    }
  }
  .b-image-3 {
    grid-column: 2 / 7;
    grid-row: 6 / 7;
    @include tablet {
      grid-column: 2 / 6;
    }
    @include phone {
      grid-column: 2 / 6;
      grid-row: 9 / 10;
    }
    img {
      width: 100%;
      vertical-align: bottom;
      @include phone {
        height: 66.7vw;
        object-fit: cover;
      }
    }
  }
  .t-3 {
    grid-column: 2 / 7;
    grid-row: 7 / 8;
    margin-top: 40px;
    @include tablet {
      grid-column: 2 / 6;
    }
    @include phone {
      grid-column: 2 / 6;
      grid-row: 10 / 11;
    }
  }
  .p-3 {
    grid-column: 2 / 7;
    grid-row: 8 / 9;
    margin-top: 12px;
    @include tablet {
      grid-column: 2 / 6;
    }
    @include phone {
      grid-column: 2 / 6;
      grid-row: 11 / 12;
    }
  }
  .b-image-4 {
    grid-column: 8 / 13;
    grid-row: 6 / 7;
    @include tablet {
      grid-column: 6 / 10;
    }
    @include phone {
      grid-column: 2 / 6;
      grid-row: 12 / 13;
    }
    img {
      width: 100%;
      vertical-align: bottom;
      @include phone {
        height: 66.7vw;
        object-fit: cover;
      }
    }
  }
  .t-4 {
    grid-column: 8 / 13;
    grid-row: 7 / 8;
    margin-top: 40px;
    @include tablet {
      grid-column: 6 / 10;
    }
    @include phone {
      grid-column: 2 / 6;
      grid-row: 13 / 14;
    }
  }
  .p-4 {
    grid-column: 8 / 13;
    grid-row: 8 / 9;
    margin-top: 12px;
    @include tablet {
      grid-column: 6 / 10;
    }
    @include phone {
      grid-column: 2 / 6;
      grid-row: 14 / 15;
    }
  }
}

</style>
